








import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { User } from '@/models';
import { AdminService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import UserList from '@/components/admin/UserList.vue';

@Component({
  components: {
    UserList,
  },
})
export default class AdminUsersPage extends Vue {
  loading: boolean = true;
  users: User[] = [];

  async created(): Promise<void> {
    try {
      this.users = await AdminService.getUsers();
    } catch (e) {
      this.$toaster.error('Error loading users', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }
}
